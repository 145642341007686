import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_SITE } from "src/constants/constants";
import { ConfirmationType } from "src/services/chatService";

type ChatResponse = {
  messageId: string;
  userText: string;
  isSuccessful: boolean;
  llmResponse: string;
  chartResult: string;
  thumbsUpClicked: boolean;
  thumbsDownClicked: boolean;
  voteResponse: string;
  isTextOnly: boolean;
  llmMessage: string;
  isEmptyChart: boolean;
  confirmationResponse: ConfirmationType | "";
  confirmationYesClicked: boolean;
  confirmationNoClicked: boolean;
};

export type ChatMessage = {
  userText: string;
  messageId: string;
  timestamp: string;
};

export type ChatState = {
  inputText: string;
  recentInput: string;
  isLoading: boolean;
  isInputDisabled: boolean;
  showResult: boolean;
  errorMessage: string;
  responses: ChatResponse[];
  conversationId: string;
  isRecentChatsLoaded: boolean;
  recentChats: ChatMessage[];
  site: string;
  isConfirmationLoading: boolean;
  confirmationLoadingMessageId: string;
};

const initialState: ChatState = {
  inputText: "",
  recentInput: "",
  isLoading: false,
  isInputDisabled: false,
  showResult: false,
  errorMessage: "",
  responses: [],
  conversationId: "",
  isRecentChatsLoaded: false,
  recentChats: [],
  site: DEFAULT_SITE,
  isConfirmationLoading: false,
  confirmationLoadingMessageId: null,
};

/** Chat Slice */
const { reducer, actions } = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    setInputText: (state, action) => {
      state.inputText = action.payload;
    },
    setRecentInput: (state, action) => {
      state.recentInput = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsInputDisabled: (state, action) => {
      state.isInputDisabled = action.payload;
    },
    setShowResult: (state, action) => {
      state.showResult = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setResponses: (state, action) => {
      action.payload === null
        ? (state.responses = [])
        : (state.responses = [...state.responses, action.payload]);
    },
    updateResponses: (state, action) => {
      const { message_id, updates } = action.payload;
      const existingResponse = state.responses.find(
        (response) => response.messageId === message_id,
      );
      if (existingResponse) {
        Object.assign(existingResponse, updates);
      }
    },
    setConversationId: (state, action) => {
      state.conversationId = action.payload;
    },
    setIsRecentChatsLoaded: (state, action) => {
      state.isRecentChatsLoaded = action.payload;
    },
    setRecentChats: (state, action) => {
      state.recentChats = action.payload.map((message) => ({
        userText: message.user_text,
        messageId: message.message_id,
        timestamp: message.timestamp.toString(), // Convert int to string
      }));
    },
    setSite: (state, action) => {
      state.site = action.payload;
    },
    setConfirmationLoading: (state, action) => {
      state.isConfirmationLoading = action.payload.isLoading;
      state.confirmationLoadingMessageId = action.payload.messageId;
    },
  },
});

export const {
  setInputText,
  setRecentInput,
  setIsLoading,
  setIsInputDisabled,
  setShowResult,
  setErrorMessage,
  setResponses,
  updateResponses,
  setConversationId,
  setIsRecentChatsLoaded,
  setRecentChats,
  setSite,
  setConfirmationLoading,
} = actions;

export default reducer;
