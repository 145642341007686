import React, { useMemo } from "react";
import Plot from "react-plotly.js";
import { DEFAULT_PLOTLY_CONFIG } from "src/constants/constants";
import "./plotlyChart.scss";
import Vote from "src/components/chat/content/vote";
import chatIcon from "src/images/safer_chat_icon.png";

const PlotlyChart = ({ chartJson, output }) => {
  const chartData = JSON.parse(chartJson);
  const data = useMemo(() => {
    return chartData.data;
  }, [chartJson]);
  const layout = useMemo(() => {
    return chartData.layout;
  }, [chartJson]);

  return (
    <>
      <img
        className="chart-chat-icon-image chat-icon-image"
        src={chatIcon}
        alt="Safer Chat Icon"
      />
      <div className="result-content result-confirmation-message">
        <Plot
          className="js-plotly-plot"
          data={data}
          layout={layout}
          config={DEFAULT_PLOTLY_CONFIG}
        />
        <Vote output={output} />
      </div>
    </>
  );
};

export default PlotlyChart;
