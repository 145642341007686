import React from "react";
import "./vote.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import thumbsUpTokens from "@amzn/meridian-tokens/base/icon/thumbs-up";
import thumbsDownTokens from "@amzn/meridian-tokens/base/icon/thumbs-down";
import checkTokens from "@amzn/meridian-tokens/base/icon/check-large";
import closeMediumTokens from "@amzn/meridian-tokens/base/icon/close-medium";
import ChatService from "src/services/chatService";
import { updateResponses } from "src/store/chatSlice";
import { sendCustomRUMEvent } from "src/utils/awsRUM";
import {
  ALIAS,
  MESSAGE_ID,
  SAFER_DOWNVOTE,
  SAFER_UPVOTE,
  TOOL_NAME,
  NO_MATCHED_TOOL,
} from "src/constants/constants";
const Vote = ({ output }) => {
  const dispatch = useAppDispatch();
  const { alias } = useAppSelector((state) => state.user);

  async function handleThumbsButtonClick(vote: string) {
    const response = await ChatService.putVote({
      message_id: output.messageId,
      vote: vote,
    });

    const toolName =
      output.llmResponse.tool_name == undefined
        ? NO_MATCHED_TOOL
        : output.llmResponse.tool_name;

    let updatedData;
    if (response.data.successful === true && vote === "UpVote") {
      updatedData = {
        thumbsUpClicked: true,
        thumbsDownClicked: false,
        voteResponse: "successful",
      };

      sendCustomRUMEvent(SAFER_UPVOTE, {
        [ALIAS]: alias,
        [MESSAGE_ID]: output.messageId,
        [TOOL_NAME]: toolName,
      });
    } else if (response.data.successful === true && vote === "DownVote") {
      updatedData = {
        thumbsUpClicked: false,
        thumbsDownClicked: true,
        voteResponse: "successful",
      };

      sendCustomRUMEvent(SAFER_DOWNVOTE, {
        [ALIAS]: alias,
        [MESSAGE_ID]: output.messageId,
        [TOOL_NAME]: toolName,
      });
    } else {
      updatedData = {
        thumbsUpClicked: false,
        thumbsDownClicked: false,
        voteResponse: "failed",
      };
    }

    const votePayload = {
      message_id: output.messageId,
      updates: updatedData,
    };
    dispatch(updateResponses(votePayload));

    const intervalId = setInterval(() => {
      clearInterval(intervalId);
      updatedData = {
        voteResponse: "",
      };
      const votePayload = {
        message_id: output.messageId,
        updates: updatedData,
      };
      dispatch(updateResponses(votePayload));
    }, 2000);
  }

  return (
    <div>
      {output.voteResponse === "successful" && (
        <div className="voting">
          <Button type="icon">
            <Icon tokens={checkTokens} className={"voted"} />
          </Button>
          <Text type="b300" className="vote-info">
            Your feedback has been submitted!
          </Text>
        </div>
      )}
      {output.voteResponse === "failed" && (
        <div className="voting">
          <Button type="icon">
            <Icon tokens={closeMediumTokens} className={"voted"} />
          </Button>
          <Text type="b300" className="vote-info">
            Failed to submit your vote. Please try again.
          </Text>
        </div>
      )}
      {output.voteResponse === "" && (
        <div className="voting">
          <Button
            type="icon"
            onClick={() => handleThumbsButtonClick("UpVote")}
            disabled={output.thumbsUpClicked}
            id={output.thumbsUpClicked ? "up-vote-clicked" : "up-vote"}
          >
            <Icon tokens={thumbsUpTokens} />
          </Button>
          <Button
            type="icon"
            onClick={() => handleThumbsButtonClick("DownVote")}
            disabled={output.thumbsDownClicked}
            id={output.thumbsDownClicked ? "down-vote-clicked" : "down-vote"}
          >
            <Icon tokens={thumbsDownTokens} />
          </Button>
          <Text type="b200" className="vote-info">
            AI-generated answer. Help us improve.
          </Text>
        </div>
      )}
    </div>
  );
};

export default Vote;
