import apiClient from "src/utils/apiClient";

export interface PostChartPayload {
  query: string;
  conversation_id: string;
}

export interface PutVotePayload {
  message_id: string;
  vote: string;
}

// create an enum to match the backend ConfirmationType
export enum ConfirmationType {
  YES = "YES",
  NO = "NO",
}

export interface PutConfirmResponsePayload {
  message_id: string;
  confirmation: ConfirmationType;
}

export interface ConfirmResponse {
  data: {
    successful: boolean;
  };
}

class ChatService {
  private readonly baseURL = "/v2/chart";
  async postChart(payload: PostChartPayload): Promise<any> {
    return await apiClient.post(`${this.baseURL}/chat`, payload);
  }

  async putVote(payload: PutVotePayload): Promise<any> {
    return await apiClient.post(`${this.baseURL}/vote`, payload);
  }

  async getRecentChats(): Promise<any> {
    return await apiClient.get(`${this.baseURL}/recent/chats`);
  }

  async putConfirmResponse(
    payload: PutConfirmResponsePayload,
  ): Promise<ConfirmResponse> {
    return await apiClient.post(`${this.baseURL}/confirm-response`, payload);
  }
}

export default new ChatService();
