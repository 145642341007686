import ChatService from "src/services/chatService";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  setInputText,
  setRecentInput,
  setIsLoading,
  setIsInputDisabled,
  setShowResult,
  setResponses,
  setErrorMessage,
  setRecentChats,
  setIsRecentChatsLoaded,
} from "src/store/chatSlice";
import {
  ALIAS,
  BREAKDOWN_QUESTION_COUNT,
  BREAKDOWN_TOOL,
  FAILED_QUESTION_COUNT,
  INJURY_RATE_QUESTION_COUNT,
  INJURY_RATE_TOOL,
  CONVERSATION_ID,
  USER_TEXT,
  TEXT_ONLY_RESPONSE,
  IS_TEXT_ONLY,
} from "src/constants/constants";
import { sendCustomRUMEvent } from "src/utils/awsRUM";

const ChatHandleSubmit = (prompt) => {
  const dispatch = useAppDispatch();
  const { alias } = useAppSelector((state) => state.user);
  const { conversationId } = useAppSelector((state) => state.chatSlice);

  return async () => {
    dispatch(setShowResult(true));
    dispatch(setIsInputDisabled(true));
    dispatch(setIsLoading(true));
    dispatch(setRecentInput(prompt));

    try {
      dispatch(setErrorMessage(""));

      if (prompt === "") {
        dispatch(setErrorMessage("Please enter the query content"));
        return;
      }

      const response = await ChatService.postChart({
        query: prompt,
        conversation_id: conversationId,
      });

      const data = response.data;

      const isTextOnly = data.successful && data.isTextOnly;
      if (isTextOnly) {
        sendCustomRUMEvent(TEXT_ONLY_RESPONSE, {
          [ALIAS]: alias,
          [USER_TEXT]: prompt,
          [CONVERSATION_ID]: conversationId,
        });
      }

      const isNotEmptyChart =
        data.chart_json !== null &&
        data.chart_json !== undefined &&
        data.chart_json.data.length > 0;

      if (isNotEmptyChart) {
        const llm_response = data.llm_response_json;

        if (INJURY_RATE_TOOL == llm_response.tool_name) {
          sendCustomRUMEvent(INJURY_RATE_QUESTION_COUNT, {
            [ALIAS]: alias,
            [USER_TEXT]: prompt,
            [CONVERSATION_ID]: conversationId,
            [IS_TEXT_ONLY]: isTextOnly,
          });
        }

        if (BREAKDOWN_TOOL == llm_response.tool_name) {
          sendCustomRUMEvent(BREAKDOWN_QUESTION_COUNT, {
            [ALIAS]: alias,
            [USER_TEXT]: prompt,
            [CONVERSATION_ID]: conversationId,
            [IS_TEXT_ONLY]: isTextOnly,
          });
        }
      }

      const responseData = {
        messageId: data.message_id,
        userText: prompt,
        isSuccessful: data.successful,
        llmResponse: data.llm_response_json,
        chartResult: JSON.stringify(data.chart_json),
        thumbsUpClicked: false,
        thumbsDownClicked: false,
        voteResponse: "",
        isTextOnly: data.isTextOnly,
        llmMessage: data.llm_message,
        isEmptyChart: !isNotEmptyChart,
        confirmationResponse: "",
        confirmationYesClicked: false,
        confirmationNoClicked: false,
      };

      dispatch(setResponses(responseData));
      if (isTextOnly) {
        dispatch(setIsInputDisabled(false));
      }

      dispatch(setIsLoading(false));
      dispatch(setInputText(""));
      const recentChats = await ChatService.getRecentChats();

      dispatch(setRecentChats(recentChats.data.recent_messages));
      dispatch(setIsRecentChatsLoaded(true));
    } catch (error) {
      dispatch(setErrorMessage("An error occurred, please try again"));
      dispatch(setIsInputDisabled(false));
      dispatch(setIsLoading(false));
      dispatch(setInputText(""));

      sendCustomRUMEvent(FAILED_QUESTION_COUNT, {
        [ALIAS]: alias,
        [USER_TEXT]: prompt,
        [CONVERSATION_ID]: conversationId,
      });
    }
  };
};

export default ChatHandleSubmit;
