import React from "react";
import "./responseConfirmation.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Button from "@amzn/meridian/button";
import ChatService from "src/services/chatService";
import {
  setErrorMessage,
  setIsInputDisabled,
  setConfirmationLoading,
  updateResponses,
} from "src/store/chatSlice";
import { ConfirmationType } from "src/services/chatService";
import { sendCustomRUMEvent } from "src/utils/awsRUM";
import Loader from "@amzn/meridian/loader";
import {
  ALIAS,
  MESSAGE_ID,
  CONFIRM_RESPONSE_YES,
  CONFIRM_RESPONSE_NO,
  CONFIRM_RESPONSE_FAILURE,
  NO_MATCHED_TOOL,
  TOOL_NAME,
} from "src/constants/constants";
const ResponseConfirmation = ({ output }) => {
  const dispatch = useAppDispatch();
  const { alias } = useAppSelector((state) => state.user);
  const { isConfirmationLoading, confirmationLoadingMessageId } =
    useAppSelector((state) => state.chatSlice);

  /**
   * Handles the confirmation response when user clicks Yes/No buttons
   * Updates the UI state and sends analytics events based on the response
   *
   * @param confirmation - The type of confirmation (YES/NO) selected by the user
   */
  async function handleConfirmResponse(confirmation: ConfirmationType) {
    let confirmationResponse;

    try {
      // Set loading states to show spinner and disable input
      dispatch(
        setConfirmationLoading({
          isLoading: true,
          messageId: output.messageId,
        }),
      );
      dispatch(setIsInputDisabled(true));

      // Make API call to backend to save the confirmation response
      const response = await ChatService.putConfirmResponse({
        message_id: output.messageId,
        confirmation: confirmation,
      });

      const confirmationYesClicked = confirmation === ConfirmationType.YES;
      const confirmationNoClicked = confirmation === ConfirmationType.NO;

      // Update the UI state with confirmation response
      confirmationResponse = {
        message_id: output.messageId,
        updates: {
          confirmationResponse: confirmation,
          confirmationYesClicked: confirmationYesClicked,
          confirmationNoClicked: confirmationNoClicked,
        },
      };

      // Get the tool name for analytics, default to NO_MATCHED_TOOL if undefined
      const toolName =
        output.llmResponse.tool_name == undefined
          ? NO_MATCHED_TOOL
          : output.llmResponse.tool_name;

      if (response.data.successful) {
        if (confirmationYesClicked) {
          sendCustomRUMEvent(CONFIRM_RESPONSE_YES, {
            [ALIAS]: alias,
            [MESSAGE_ID]: output.messageId,
            [TOOL_NAME]: toolName,
          });
        }

        if (confirmationNoClicked) {
          sendCustomRUMEvent(CONFIRM_RESPONSE_NO, {
            [ALIAS]: alias,
            [MESSAGE_ID]: output.messageId,
            [TOOL_NAME]: toolName,
          });
        }
      } else {
        // Send failure analytics event if API returns unsuccessful
        // It usually happens when there's an exception updating DynamoDB
        sendCustomRUMEvent(CONFIRM_RESPONSE_FAILURE, {
          [ALIAS]: alias,
          [MESSAGE_ID]: output.messageId,
          [TOOL_NAME]: toolName,
        });
      }
    } catch (error) {
      dispatch(setErrorMessage("An error occurred, please try again"));
      sendCustomRUMEvent(CONFIRM_RESPONSE_FAILURE, {
        [ALIAS]: alias,
        [MESSAGE_ID]: output.messageId,
      });
    } finally {
      // Reset loading states regardless of success/failure
      setTimeout(function () {
        dispatch(
          setConfirmationLoading({
            isLoading: false,
            messageId: null,
          }),
        );
        dispatch(setIsInputDisabled(false));
        if (confirmationResponse) {
          dispatch(updateResponses(confirmationResponse));
        }
      }, 500);
    }
  }

  return (
    <div className="confirmation-container">
      {output.confirmationResponse === "" && (
        <>
          <Button
            type="secondary"
            onClick={() => handleConfirmResponse(ConfirmationType.YES)}
            className="confirmation-button"
          >
            Yes
          </Button>
          <Button
            type="secondary"
            onClick={() => handleConfirmResponse(ConfirmationType.NO)}
            className="confirmation-button"
          >
            No
          </Button>
        </>
      )}
      {isConfirmationLoading &&
        confirmationLoadingMessageId === output.messageId && (
          <div className="confirmation-loader">
            <Loader type="circular" size="medium" />
          </div>
        )}
    </div>
  );
};

export default ResponseConfirmation;
