import Text from "@amzn/meridian/text";
import React from "react";
import "./chatResult.scss";
import chatIcon from "src/images/safer_chat_icon.png";
import PlotlyChart from "src/components/plotlyChart/plotlyChart";
import ResponseConfirmation from "src/components/chat/content/responseConfirmation";
import Button from "@amzn/meridian/button";
import { useAppSelector } from "src/store/store";
import Thumbnail from "@amzn/meridian/thumbnail";
import { BADGE_PHOTO_THUMBNAIL_URL } from "src/config/external-links";

const ChatResult = ({ output }) => {
  const { alias } = useAppSelector((state) => state.user);

  return (
    <div className="result-data">
      {output.isTextOnly ? (
        <div className="message-section">
          <img
            className="chat-icon-image"
            src={chatIcon}
            alt="Safer Chat Icon"
          />
          <div className="result-text-only">
            <Text type="b400">{output.llmMessage}</Text>
          </div>
        </div>
      ) : (
        <div className="chat-result-container">
          <div className="message-section">
            <img
              className="chat-icon-image"
              src={chatIcon}
              alt="Safer Chat Icon"
            />
            <div className="result-confirmation-message">
              <Text type="b400">{output.llmMessage}</Text>
              <ResponseConfirmation output={output} />
            </div>
          </div>
          {output.confirmationYesClicked && (
            <>
              <div className="confirmation-yes-section">
                <div>
                  <Thumbnail
                    size="small"
                    source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`}
                  />
                  <Button
                    type="secondary"
                    disabled={true}
                    id="confirm-response-yes-clicked"
                    className="confirmation-button-clicked"
                  >
                    Yes
                  </Button>
                </div>
              </div>
              <div className="chart-section">
                <PlotlyChart chartJson={output.chartResult} output={output} />
              </div>
            </>
          )}
          {output.confirmationNoClicked && (
            <>
              <div className="confirmation-no-section">
                <div>
                  <Thumbnail
                    size="small"
                    source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`}
                  />
                  <Button
                    type="secondary"
                    disabled={true}
                    id="confirm-response-no-clicked"
                    className="confirmation-button-clicked"
                  >
                    No
                  </Button>
                </div>
              </div>
              <div className="confirmation-feedback-section">
                <img
                  className="confirmation-chat-icon-image chat-icon-image"
                  src={chatIcon}
                  alt="Safer Chat Icon"
                />
                <div className="result-content result-confirmation-message confirmation-feedback-content">
                  <Text type="b400">
                    Let's try to get you a better answer, you can
                  </Text>
                  <Text type="b400" tag="ul">
                    <li>Be more specific with your question</li>
                    <li>Include relevant context or details</li>
                    <li>Rephrase your question in a different way</li>
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatResult;
